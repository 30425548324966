import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import { classnames } from 'begonya/functions'

import { useApp } from '@/core/contexts/app'

import { Column, Container, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor/Anchor'
import Icon from '@/components/base/icon/Icon'
import Image from '@/components/base/image/Image'

import { PartnersProps } from './type'
import { useWidth } from '@/core/hooks/useWidthResize'
import { useMobile } from '@/core/hooks'

const Partners: React.FunctionComponent<PartnersProps> = ({ title, data }) => {
  const slider = useRef<Slider>(null)
  const width = useWidth()
  const app = useApp()
  const isMobile = useMobile(app.headers['user-agent'])
  const renderSlider = () => {
    const settings = {
      dots: false,
      infinite: data && data.length > 6,
      slidesToShow: isMobile ? 2 : 6,
      slidesToScroll: 1,
      autoplay: false,
      speed: 500,
      autoplaySpeed: 4000,
      cssEase: 'linear',
      arrows: false,
    }

    return (
      <div className="partners-slide">
        <Container size="medium">
          <Row>
            <Column className="slidee" xs={{ size: 12 }}>
              {data && data.length > 6 && (
                <button
                  className="partners-slide-arrow partners-slide-arrow--prev"
                  onClick={() => slider.current?.slickPrev()}
                >
                  <Icon name="arrow-back" />
                </button>
              )}
              <Slider {...settings} ref={slider}>
                {data?.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="item-in">
                      <figure>
                        {/* <Anchor className="more" {...item.link}> */}
                        <Image {...item.image} alt={item.title} />
                        {/* </Anchor> */}
                      </figure>
                    </div>
                  </div>
                ))}
              </Slider>
              {data && data.length > 3 && (
                <button
                  className="partners-slide-arrow partners-slide-arrow--next"
                  onClick={() => slider.current?.slickNext()}
                >
                  <Icon name="arrow-forward" />
                </button>
              )}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div className="partners section">
      <Container size="fluid">
        <Row xs={{ align: 'center' }}>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
            className="text-center"
          >
            <div className="section-title">{title}</div>
          </Column>
        </Row>
        <Row>{renderSlider()}</Row>
      </Container>
    </div>
  )
}

export default Partners
